var createPopup = function($source) {
    this.$source = $source;
    this.url =  $source.attr('href');
    this.param = ($source.data('param')) ? $source.data('param') : 'name';
    this.title = ($source.data('title')) ? $source.data('title') : 'Nowy';
    this.description = ($source.data('description')) ? $source.data('description') : '';
    this.method = ($source.data('method')) ? $source.data('method') : 'GET';
    this.value = ($source.data('value')) ? $source.data('value') : '';

    this.$modal = null;
    this.$input = null;

    var that = this;
    // this.$source.click(function(e){e.preventDefault(); that.create();});
}

createPopup.prototype.create = function() {
    var template='';
    template += '<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\">';
    template += '  <div class=\"modal-dialog\" role=\"document\">';
    template += '    <div class=\"modal-content\">';
    template += '      <div class=\"modal-header\">';
    template += '        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span aria-hidden=\"true\">&times;<\/span><\/button>';
    template += '        <h4 class=\"modal-title\">'+this.title+'<\/h4>';
    template += '      </div>';
    template += '      <div class=\"modal-body\">';
    template += '        <p>'+this.description+'<\/p>';
    template += '        <input type="text" class="form-control" placeholder="Podaj nazwę" value="'+this.value+'">  ';
    template += '      </div>';
    template += '      <div class=\"modal-footer\">';
    template += '        <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">Anuluj<\/button>';
    template += '        <button type=\"button\" class=\"btn btn-primary btn-save\">Zapisz<\/button>';
    template += '      </div>';
    template += '    </div>';
    template += '  </div>';
    template += '</div>';
    
    this.$modal = $(template);
    $('body').append(this.$modal);

    this.$input = this.$modal.find('input[type=text]');

    this.$modal.modal();
    this.bindEvents();
}

createPopup.prototype.bindEvents = function() {
    var that = this;
    this.$modal.find('.btn-save').click(function(e){
        e.preventDefault();
        that.save();
    });
    this.$modal.on('hidden.bs.modal', function (e) {
        that.destroy();
    })
}

createPopup.prototype.destroy = function() {
    this.$modal.remove();
}


createPopup.prototype.save = function() {
    var that  = this;
    var data = {};
    data[this.param] = this.$input.val();

    if (this.method == 'GET') {

        var c = (this.url.indexOf('?') === -1) ? '?' : '&';

        window.location.href = this.url+c+this.param+"="+this.$input.val();
    } else if (this.method == "POST") {
        data["_token"] = $('meta[name=csrf-token]').attr('content');
        $.ajax({
            url: this.url,
            method: this.method,
            data: data
        }).done(function(){
            that.$source.data('value', data[that.param]);
            that.$modal.modal('hide');
        });
    }

}



function documentready(fn) {
    if (document.readyState != 'loading'){
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}


documentready(function(){


    $(document).delegate('[data-create]', 'click',  function(e){
        e.preventDefault();
        var cp = new createPopup($(this));
        cp.create();
    })

    // $('[data-create]').each(function(){
    //     new createPopup($(this));
    // });
});