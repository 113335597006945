/**
 * Created by maciek on 08/10/16.
 */
function initSummernote(id) {
    $(id).summernote({
        lang: 'pl-PL',
        height: 500,
        callbacks: {
            onImageUpload: function(files, editor, $editable) {
                sendFile(files[0],editor,$editable);
            }
        },
        toolbar: [
            // [groupName, [list of button]]
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['height', ['height']],
            ['insert', ['picture', 'link', 'table']],
            ['advanced', ['fullscreen', 'codeview', 'undo', 'redo'] ]
        ]
    });
    function sendFile(file,editor,welEditable) {
        data = new FormData();
        data.append("image", file);
//                data.append("partner", true);
        jQuery.ajax({
            url: "/admin/upload/image",
            data: data,
            headers: {'X-CSRF-Token': $('input[name="_token"]').val()},
            cache: false,
            contentType: false,
            processData: false,
            dataType: "json",
            type: 'POST',
            success: function(s){

                var $photo = $('input[name=photo_id]');
                if ($photo.length &&  ($photo.val().length==0 || parseInt($photo.val())===0)) $photo.val(s.id);
                if ($('#photo').length) $('#photo').attr('src', s.thumb);
                jQuery(id).summernote("insertImage", s.url);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus+" "+errorThrown);
            }
        });
    }

    $('#remove_main_photo').click(function(e){
        e.preventDefault();
        var $photo = $('input[name=photo_id]');
        if ($photo.length) $photo.val('');
        if ($('#photo').length) $('#photo').attr('src', '');
    });
}
